import React, { useState } from "react";
import { graphql } from "gatsby";
import { Container, Box, Heading, Text, Input } from "theme-ui";

import { PostList, TagsMenu } from "../components/";
import Layout from "../components/global/Layout";

const ListTemplate = ({ data, pageContext }) => {
	const { markdownRemark } = data;
	const { title, subheading } = markdownRemark.frontmatter;
	const allPosts = pageContext.children;
	const [state, setState] = useState({
		filteredPosts: [],
		query: "",
	});

	const handleInputChange = (event) => {
		const query = event.target.value;
		const filteredPosts = allPosts.filter((post) => {
			const { title, tags, author } = post.node.frontmatter;

			return (
				title.toLowerCase().includes(query.toLowerCase()) ||
				(author && author.toLowerCase().includes(query.toLowerCase())) ||
				(tags &&
					tags
						.join(" ")
						.toLowerCase()
						.includes(query.toLowerCase()))
			);
		});
		setState({
			query,
			filteredPosts,
		});
		console.log(filteredPosts);
	};

	const posts = state.query ? state.filteredPosts : allPosts;

	return (
		<Layout>
			<Box as="section">
				<Container px={[3, 4]}>
					<Box py={(3, 4)}>
						<Heading as="h1" variant="headingL" mb={[2, 3]}>
							{title}
						</Heading>
						{subheading && (
							<Text as="p" variant="bodyLarge" mb={[1, 2]}>
								{subheading}
							</Text>
						)}
					</Box>

					<Input
						type="text"
						aria-label="Search"
						placeholder="Search posts"
						value={state.query}
						onChange={handleInputChange}
						tabIndex={0}
					/>
					<TagsMenu mb={[3, 4]} />
					<Box as="section">
						<PostList posts={posts} />
					</Box>
				</Container>
			</Box>
		</Layout>
	);
};

export default ListTemplate;

export const query = graphql`
	query ListPageBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				subheading
				draft
			}
			fields {
				slug
			}
		}
	}
`;
